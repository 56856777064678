<template>
    <div align="left" justify="center" class="">
        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-btn class="grey lighten-4" x-small fab icon
                        ><v-icon class="primary--text lighten-2 pa-2">
                            search
                        </v-icon></v-btn
                    >
                    Administração > Pesquisa Avançada
                </v-card-text>
            </v-card>

            <v-card @keyup.native.enter="pesquisaAvancadaTitulos" class="my-2">
                <v-expansion-panels v-model="panel" class="px-3 pt-3 pb-0">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-card-text
                                class="py-0 px-3 font-weight-light white black--text"
                            >
                                <v-btn class="grey lighten-4" x-small fab icon
                                    ><v-icon
                                        class="primary--text lighten-2 pa-2"
                                    >
                                        mdi-filter
                                    </v-icon></v-btn
                                >
                                Filtros
                            </v-card-text>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row align="center" class="ma-0 pa-0">
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="4"
                                    lg="4"
                                    class="d-flex flex-row"
                                >
                                    <v-text-field
                                        solo
                                        small
                                        class="mt-5"
                                        label="Nosso número"
                                        hint="Informe o nosso número"
                                        placeholder="Ex.:477977"
                                        v-model="filtros.ds_nosso_numero"
                                        clearable
                                        data-cy="filtroNossoNumero"
                                        :loading="loading"
                                    ></v-text-field>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class=""
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Informe o Número do Título</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="4"
                                    lg="4"
                                    class="d-flex flex-row align-center"
                                >
                                    <v-text-field
                                        solo
                                        small
                                        class="mt-5"
                                        label="CPF/CNPJ"
                                        hint="Informe o Número do CPF/CNPJ"
                                        placeholder="Ex.:000.000.000-00"
                                        v-mask="[
                                            '###.###.###-##',
                                            'XX.XXX.XXX/XXXX-##'
                                        ]"
                                        v-model="
                                            filtros.nu_identificacao_devedor
                                        "
                                        clearable
                                        data-cy="filtroCpfCnpj"
                                        :loading="loading"
                                    >
                                    </v-text-field>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="grey--text"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Informe o CPF/CNPJ, ou apenas seus
                                            números iniciais
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="4"
                                    lg="4"
                                    class="d-flex flex-row"
                                >
                                    <v-text-field
                                        solo
                                        small
                                        class="mt-5"
                                        label="Nome"
                                        hint="Informe o nome para efetuar a busca"
                                        placeholder="Ex.: José"
                                        clearable
                                        v-model="filtros.nm_devedor"
                                        data-cy="nomeDevedor"
                                        :loading="loading"
                                    ></v-text-field>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Informe o Nome que deseja encontrar,
                                            por exemplo: Ricardo
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    class="d-flex flex-row"
                                >
                                    <v-textarea
                                        auto-grow
                                        solo
                                        small
                                        class="mt-5"
                                        v-bind:style="{
                                            maxHeight: '15rem',
                                            overflowY: 'auto'
                                        }"
                                        rows="1"
                                        label="Número do Protocolo"
                                        hint="Informe o número do protocolo para efetuar a busca"
                                        clearable
                                        data-cy="numeroProtocolo"
                                        v-model="
                                            filtros.apontamento_nu_protocolo
                                        "
                                        :loading="loading"
                                    ></v-textarea>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Informe o protocolo que deseja
                                            encontrar
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="3"
                                    lg="3"
                                    xl="3"
                                    class="d-flex flex-row"
                                >
                                    <v-text-field
                                        v-if="!motivosRevisao.length"
                                        solo
                                        small
                                        class="mt-5"
                                        label="Código Revisão Interna"
                                        hint="Informe o código da Revisão Interna"
                                        clearable
                                        v-model="filtros.cd_revisao_interna"
                                        data-cy="revisaoInterna"
                                        :loading="loading"
                                        maxlength="3"
                                    ></v-text-field>
                                    <v-autocomplete
                                        v-else
                                        solo
                                        small
                                        label="Código Revisão Interna"
                                        class="mt-5"
                                        clearable
                                        v-model="filtros.cd_revisao_interna"
                                        :items="motivosRevisao"
                                        item-value="cd_revisao"
                                        item-text="cd_revisao"
                                        :loading="loading"
                                    ></v-autocomplete>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Informe o código da Revisão Interna
                                            que deseja filtrar
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="3"
                                    lg="3"
                                    class="d-flex flex-row"
                                >
                                    <v-autocomplete
                                        solo
                                        small
                                        label="Comarca"
                                        class="mt-5"
                                        clearable
                                        v-model="filtros.cd_ibge_comarca"
                                        :items="comarcas"
                                        item-value="cd_ibge_comarca"
                                        item-text="nm_comarca"
                                        :loading="loading || loadingComarcas"
                                    />
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Selecione a comarca desejada
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    class="d-flex flex-row pa-0"
                                >
                                    <v-col
                                        cols="12"
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        class="d-flex flex-row"
                                    >
                                        <v-menu
                                            v-model="dataApontamentoInicial"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    class="mt-5"
                                                    solo
                                                    small
                                                    v-model="
                                                        dataApontamentoInicialFormatada
                                                    "
                                                    label="Data Apontamento Inicial"
                                                    placeholder="Selecione no botão ao lado"
                                                    clearable
                                                    @change="
                                                        limparData(
                                                            'apontamentoInicial'
                                                        )
                                                    "
                                                    readonly
                                                ></v-text-field>
                                                <v-btn
                                                    fab
                                                    x-small
                                                    elevation="0"
                                                    class="grey--text ma-auto ml-2"
                                                >
                                                    <v-icon class="" v-on="on">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    filtros.data_apontamento_inicial
                                                "
                                                :max="computedMaxDate"
                                                color="primary"
                                                @input="
                                                    validarSelecaoData(
                                                        $event,
                                                        'dataApontamentoInicial'
                                                    )
                                                "
                                                locale="pt-br"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        lg="6"
                                        class="d-flex flex-row"
                                    >
                                        <v-menu
                                            v-model="dataApontamentoFinal"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    class="mt-5"
                                                    solo
                                                    small
                                                    v-model="
                                                        dataApontamentoFinalFormatada
                                                    "
                                                    label="Data Apontamento Final"
                                                    placeholder="Selecione no botão ao lado"
                                                    clearable
                                                    @change="
                                                        limparData(
                                                            'apontamentoFinal'
                                                        )
                                                    "
                                                    readonly
                                                ></v-text-field>
                                                <v-btn
                                                    fab
                                                    x-small
                                                    elevation="0"
                                                    class="grey--text ma-auto ml-2"
                                                >
                                                    <v-icon class="" v-on="on">
                                                        mdi-calendar
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    filtros.data_apontamento_final
                                                "
                                                :min="computedMinDate"
                                                color="primary"
                                                @input="
                                                    validarSelecaoData(
                                                        $event,
                                                        'dataApontamentoFinal'
                                                    )
                                                "
                                                locale="pt-br"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    class="d-flex flex-row"
                                >
                                    <v-text-field
                                        solo
                                        small
                                        class="mt-5"
                                        label="E-mail"
                                        hint="Informe o e-mail para efetuar a busca"
                                        clearable
                                        v-model="filtros.ds_email_devedor"
                                        data-cy="emailDevedor"
                                        :loading="loading"
                                    ></v-text-field>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Informe o e-mail que deseja
                                            encontrar
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    xl="6"
                                    class="d-flex flex-row"
                                >
                                    <v-autocomplete
                                        solo
                                        small
                                        label="Apresentante"
                                        class="mt-5"
                                        clearable
                                        v-model="filtros.cd_apresentante"
                                        :items="apresentantes"
                                        item-value="cd_apresentante"
                                        item-text="nm_apresentante"
                                        :loading="loading"
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span
                                                        :class="{
                                                            'text-gray': !data
                                                                .item.st_ativo
                                                        }"
                                                        >{{
                                                            data.item
                                                                .nm_apresentante
                                                        }}</span
                                                    ><br />
                                                    <div>
                                                        <small>Remessa: </small>
                                                        <small
                                                            :class="
                                                                data.item
                                                                    .st_ativo_remessa
                                                                    ? 'text-green'
                                                                    : 'text-error'
                                                            "
                                                            >{{
                                                                !!data.item
                                                                    .st_ativo_remessa
                                                                    ? 'Ativo'
                                                                    : 'Inativo'
                                                            }}</small
                                                        >
                                                        <small>
                                                            | Comando:
                                                        </small>
                                                        <small
                                                            :class="
                                                                !!data.item
                                                                    .st_ativo_comando
                                                                    ? 'text-green'
                                                                    : 'text-error'
                                                            "
                                                            >{{
                                                                !!data.item
                                                                    .st_ativo_comando
                                                                    ? 'Ativo'
                                                                    : 'Inativo'
                                                            }}</small
                                                        >
                                                    </div>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Informe o apresentante que deseja
                                            encontrar - filtro por Razão social
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="3"
                                    lg="3"
                                    xl="3"
                                    class="d-flex flex-row"
                                >
                                    <v-autocomplete
                                        solo
                                        small
                                        label="Sigla Apresentante"
                                        class="mt-5"
                                        clearable
                                        v-model="filtros.cd_apresentante"
                                        :items="apresentantesOrderByCd"
                                        item-value="cd_apresentante"
                                        item-text="cd_apresentante"
                                        :loading="
                                            loading || loadingApresentantes
                                        "
                                    ></v-autocomplete>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Informe o apresentante que deseja
                                            encontrar - filtro por sigla
                                        </span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="3"
                                    lg="3"
                                    class="d-flex flex-row"
                                >
                                    <v-switch
                                        v-model="filtros.busca_exata"
                                        hide-details
                                        small
                                        class="mt-0 d-flex justify-center"
                                        color="primary"
                                        label="Busca exata"
                                    ></v-switch>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                fab
                                                x-small
                                                class="ma-auto ml-2"
                                                elevation="0"
                                            >
                                                <v-icon
                                                    color="grey"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Utilizar busca exata (aplicável ao
                                            Nosso Número)
                                        </span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-col xs="12" sm="12" md="12" lg="12" class="text-right">
                    <v-btn
                        class="mr-3"
                        :loading="loading"
                        @click="limparFiltros"
                    >
                        Limpar Filtros
                        <v-icon class="ml-2">mdi-filter-remove-outline</v-icon>
                    </v-btn>
                    <v-btn
                        class="ma-0"
                        color="primary"
                        :loading="loading"
                        @click="pesquisaAvancadaTitulos"
                    >
                        Filtrar
                        <v-icon class="ml-2">search</v-icon>
                    </v-btn>
                </v-col>
            </v-card>

            <v-data-table
                :headers="headers"
                :items="titulos"
                calculate-widths
                :options.sync="options"
                :server-items-length="totalTitulos"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, 500],
                    itemsPerPageText: 'Itens por página:'
                }"
                loading-text="Pesquisando títulos..."
                no-data-text="Nenhum resultado encontrado... Faça uma pesquisa"
                no-results-text="Nenhum título encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.id_titulo`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                    >
                        {{ item.id_titulo }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.id_titulo
                    }}</span>
                </template>
                <template v-slot:[`item.ds_nosso_numero`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.ds_nosso_numero }}</span
                    >
                    <span v-else :style="{ color: 'black' }"
                        >{{ item.ds_nosso_numero }}
                    </span>
                </template>
                <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ formatDocumento(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        formatDocumento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nm_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.nm_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.nm_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.cd_status`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ getDescSituacao(item) }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        getDescSituacao(item)
                    }}</span>
                </template>
                <template v-slot:[`item.cd_uf_devedor`]="{ item }">
                    <span
                        v-if="item.ds_revisao != '' && item.cd_status == 99999"
                        title="Este título precisa ser revisado. "
                        :style="{ color: 'red' }"
                        >{{ item.cd_uf_devedor }}</span
                    >
                    <span v-else :style="{ color: 'black' }">{{
                        item.cd_uf_devedor
                    }}</span>
                </template>
                <template slot="item.action" slot-scope="props">
                    <!-- v-if="isVersionBeta" -->
                    <v-btn
                        elevation="1"
                        width="100%"
                        class="mr-2 grey white--text"
                        @click="abrirDetalhesTitulo(props.item)"
                        full-width
                        small
                        :style="{ color: 'primary' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon class="mr-2" :style="{ color: 'white' }">
                            edit
                        </v-icon>
                        Detalhes
                    </v-btn>
                    <!-- <v-btn
                        v-else-if="
                            props.item.ds_revisao != '' &&
                                props.item.cd_status == 99999
                        "
                        title="Este título precisa ser revisado. "
                        elevation="1"
                        class="mr-2"
                        width="100%"
                        @click="exibirDetalhe(props.item)"
                        :style="{ color: 'red' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            title="Este título precisa ser revisado. "
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'red' }"
                            >edit</v-icon
                        >
                        Revisar
                    </v-btn>
                    <v-btn
                        v-else
                        elevation="1"
                        width="100%"
                        class="mr-2 grey white--text"
                        @click="exibirDetalhe(props.item)"
                        full-width
                        small
                        :style="{ color: 'primary' }"
                        :data-cy="`editarTitulo${props.index}`"
                    >
                        <v-icon
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >edit
                        </v-icon>
                        Detalhes
                    </v-btn> -->
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { getOnlyNumbers } from '@/helpers/Utilitarios';
import * as Formatter from '@/helpers/Formatter';
import ApresentanteService from '../services/apresentanteService';
import ComarcaService from '../services/ComarcaService';
import TituloService from '../services/TituloService';
import lodash from 'lodash';
import MotivoRevisaoService from '../services/MotivoRevisaoService';

export default {
    name: 'PesquisaAvancada',
    components: {},
    data() {
        return {
            apresentanteService: new ApresentanteService(Vue.http, this.$store),
            comarcaService: new ComarcaService(Vue.http, this.$store),
            tituloService: new TituloService(Vue.http, this.$store),
            motivoRevisaoService: new MotivoRevisaoService(
                Vue.http,
                this.$store
            ),
            panel: 0,
            dataApontamentoInicial: false,
            dataApontamentoFinal: false,
            motivosRevisao: [],
            apresentantes: [],
            comarcas: [],
            apresentantesOrderByCd: [],
            filtros: { busca_exata: false },
            loading: false,
            loadingApresentantes: false,
            loadingComarcas: false,
            headers: [
                {
                    text: 'ID',
                    sortable: true,
                    value: 'id_titulo'
                },
                {
                    text: 'Nosso Número',
                    sortable: true,
                    value: 'ds_nosso_numero'
                },
                {
                    text: 'CPF/CNPJ',
                    sortable: true,
                    value: 'nu_identificacao_devedor'
                },
                {
                    text: 'Nome',
                    sortable: true,
                    value: 'nm_devedor'
                },
                {
                    text: 'Apresentante',
                    sortable: true,
                    value: 'cd_apresentante'
                },
                {
                    text: 'Protocolo',
                    sortable: false,
                    value: 'apontamento_nu_protocolo'
                },
                {
                    text: 'Envio',
                    sortable: false,
                    value: 'st_envio'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'cd_status'
                },
                {
                    text: 'UF',
                    sortable: false,
                    value: 'cd_uf_devedor'
                },
                {
                    text: 'Nº Devedores',
                    sortable: false,
                    value: 'nu_quantidade_devedores'
                },
                { text: ' ', sortable: false, value: 'action', width: '15%' }
            ],
            titulos: [],
            options: {
                page: 1,
                itemsPerPage: 10
            },
            totalTitulos: 0
        };
    },
    async mounted() {
        this.listarApresentantes();
        this.listarComarcas();
        await this.listarMotivosRevisao();
    },
    computed: {
        computedMinDate() {
            return this.filtros.data_apontamento_inicial
                ? this.filtros.data_apontamento_inicial
                : null;
        },
        computedMaxDate() {
            return this.filtros.data_apontamento_final
                ? this.filtros.data_apontamento_final
                : null;
        },
        dataApontamentoInicialFormatada: {
            get: function() {
                const dataFormatada = Formatter.formatData(
                    this.filtros.data_apontamento_inicial
                );
                return dataFormatada;
            },
            set: function(value) {
                this.dataApontamentoInicial = value;
            }
        },
        dataApontamentoFinalFormatada: {
            get: function() {
                const dataFormatada = Formatter.formatData(
                    this.filtros.data_apontamento_final
                );
                return dataFormatada;
            },
            set: function(value) {
                this.dataApontamentoFinal = value;
            }
        }
    },
    watch: {
        options: {
            handler() {
                if (this.totalTitulos > 0) {
                    this.pesquisaAvancadaTitulos();
                }
            },
            deep: true
        }
    },
    methods: {
        getOnlyNumbers,
        validarSelecaoData(val, booleano) {
            if (val && val.length === 2) {
                this[booleano] = false;
            }
        },
        limparData(tipoData) {
            if (tipoData === 'apontamentoInicial') {
                delete this.filtros.data_apontamento_inicial;
            }
            if (tipoData === 'apontamentoFinal') {
                delete this.filtros.data_apontamento_inicial;
            }
        },
        limparFiltros() {
            this.filtros = { busca_exata: false };
        },
        abrirDetalhesTitulo(titulo) {
            const routerDetalheTitulo = this.$router.resolve({
                name: 'detalhe-titulo',
                query: {
                    id_titulo: titulo.id_titulo,
                    cd_apresentante: titulo.cd_apresentante,
                    new_tab: true
                }
            });
            window.open(routerDetalheTitulo.href, '_blank');
        },
        listarApresentantes() {
            this.loadingApresentantes = true;
            return new Promise((resolve, reject) => {
                try {
                    this.apresentanteService
                        .listarApresentantes(this.filterSelected)
                        .then(
                            response => {
                                if (response.status == 200) {
                                    let arr_apresentantes = [...response.body];
                                    this.apresentantesOrderByCd = response.body;

                                    arr_apresentantes.sort((a, b) => {
                                        let nomeA = a.nm_apresentante.toUpperCase();
                                        let nomeB = b.nm_apresentante.toUpperCase();
                                        if (nomeA < nomeB) {
                                            return -1;
                                        }
                                        if (nomeA > nomeB) {
                                            return 1;
                                        }
                                        return 0;
                                    });

                                    this.apresentantes = arr_apresentantes;
                                }
                            },
                            error => {
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                } finally {
                    this.loadingApresentantes = false;
                }
            });
        },
        listarComarcas() {
            this.loadingComarcas = true;
            return new Promise((resolve, reject) => {
                try {
                    this.comarcaService.buscarComarcas({}).then(
                        response => {
                            this.comarcas = response.body;
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                } finally {
                    this.loadingComarcas = false;
                }
            });
        },
        async listarMotivosRevisao() {
            try {
                this.loading = true;

                const response = await this.motivoRevisaoService.listarMotivosRevisao();
                this.motivosRevisao = response;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        ajustarFiltroProtocolo() {
            if (this.filtros.apontamento_nu_protocolo) {
                this.filtros.apontamento_nu_protocolo = this.filtros.apontamento_nu_protocolo
                    .replace(/\s+/g, ' ')
                    .trim();
            }
        },
        async pesquisaAvancadaTitulos() {
            try {
                this.loading = true;
                this.ajustarFiltroProtocolo();
                const filtro = lodash.cloneDeep(this.filtros);

                if (filtro.nu_identificacao_devedor) {
                    filtro.nu_identificacao_devedor = this.getOnlyNumbers(
                        this.filtros.nu_identificacao_devedor
                    );
                }

                const response = await this.tituloService.pesquisaAvancadaTitulos(
                    filtro,
                    this.options.page,
                    this.options.itemsPerPage
                );

                this.titulos = response.titulos;
                this.totalTitulos = response.total_titulos;
            } catch (error) {
                console.log(error);
            } finally {
                this.panel = undefined;
                this.loading = false;
            }
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        },
        exibirDetalhe(titulo) {
            this.snackbarLateral = false;
            this.$store.commit('setTitulo', titulo);
            this.ultimoTitulo = titulo;
            this.dialogLoading = true;
            this.somaCounter();
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService.detalharTitulo(titulo).then(
                response => {
                    const { detalhe_titulo, historico_titulo } = response.body;
                    this.titulo = detalhe_titulo;
                    this.isSaoPaulo =
                        this.titulo.cd_uf_devedor === 'SP' ? true : false;
                    this.historicos = historico_titulo;
                    this.dialog = true;
                    this.dialogLoading = false;
                    this.listarEmailsEnviados();
                    this.verificaStEnvio();
                    if (this.superUsuario === true) {
                        // this.verificarApresentanteProtestado();
                        if (!this.usuarioPartner) {
                            this.listarComentarios(titulo);
                        }
                    }
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        }
    }
};
</script>

<style lang="scss"></style>
