var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { align: "left", justify: "center" } }, [
    _c(
      "div",
      { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
      [
        _c(
          "v-card",
          { attrs: { align: "left", justify: "left" } },
          [
            _c(
              "v-card-text",
              { staticClass: "pa-3 font-weight-light white black--text" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "grey lighten-4",
                    attrs: { "x-small": "", fab: "", icon: "" }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "primary--text lighten-2 pa-2" },
                      [_vm._v(" search ")]
                    )
                  ],
                  1
                ),
                _vm._v(" Administração > Pesquisa Avançada ")
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-card",
          {
            staticClass: "my-2",
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.pesquisaAvancadaTitulos.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "v-expansion-panels",
              {
                staticClass: "px-3 pt-3 pb-0",
                model: {
                  value: _vm.panel,
                  callback: function($$v) {
                    _vm.panel = $$v
                  },
                  expression: "panel"
                }
              },
              [
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      [
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "py-0 px-3 font-weight-light white black--text"
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "grey lighten-4",
                                attrs: { "x-small": "", fab: "", icon: "" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "primary--text lighten-2 pa-2"
                                  },
                                  [_vm._v(" mdi-filter ")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" Filtros ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "ma-0 pa-0",
                            attrs: { align: "center" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "4",
                                  lg: "4"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    solo: "",
                                    small: "",
                                    label: "Nosso número",
                                    hint: "Informe o nosso número",
                                    placeholder: "Ex.:477977",
                                    clearable: "",
                                    "data-cy": "filtroNossoNumero",
                                    loading: _vm.loading
                                  },
                                  model: {
                                    value: _vm.filtros.ds_nosso_numero,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "ds_nosso_numero",
                                        $$v
                                      )
                                    },
                                    expression: "filtros.ds_nosso_numero"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Informe o Número do Título")
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row align-center",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "4",
                                  lg: "4"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: [
                                        "###.###.###-##",
                                        "XX.XXX.XXX/XXXX-##"
                                      ],
                                      expression:
                                        "[\n                                        '###.###.###-##',\n                                        'XX.XXX.XXX/XXXX-##'\n                                    ]"
                                    }
                                  ],
                                  staticClass: "mt-5",
                                  attrs: {
                                    solo: "",
                                    small: "",
                                    label: "CPF/CNPJ",
                                    hint: "Informe o Número do CPF/CNPJ",
                                    placeholder: "Ex.:000.000.000-00",
                                    clearable: "",
                                    "data-cy": "filtroCpfCnpj",
                                    loading: _vm.loading
                                  },
                                  model: {
                                    value: _vm.filtros.nu_identificacao_devedor,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "nu_identificacao_devedor",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                                        filtros.nu_identificacao_devedor\n                                    "
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "grey--text"
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Informe o CPF/CNPJ, ou apenas seus números iniciais "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "4",
                                  lg: "4"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    solo: "",
                                    small: "",
                                    label: "Nome",
                                    hint: "Informe o nome para efetuar a busca",
                                    placeholder: "Ex.: José",
                                    clearable: "",
                                    "data-cy": "nomeDevedor",
                                    loading: _vm.loading
                                  },
                                  model: {
                                    value: _vm.filtros.nm_devedor,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filtros, "nm_devedor", $$v)
                                    },
                                    expression: "filtros.nm_devedor"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Informe o Nome que deseja encontrar, por exemplo: Ricardo "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "6",
                                  lg: "6"
                                }
                              },
                              [
                                _c("v-textarea", {
                                  staticClass: "mt-5",
                                  style: {
                                    maxHeight: "15rem",
                                    overflowY: "auto"
                                  },
                                  attrs: {
                                    "auto-grow": "",
                                    solo: "",
                                    small: "",
                                    rows: "1",
                                    label: "Número do Protocolo",
                                    hint:
                                      "Informe o número do protocolo para efetuar a busca",
                                    clearable: "",
                                    "data-cy": "numeroProtocolo",
                                    loading: _vm.loading
                                  },
                                  model: {
                                    value: _vm.filtros.apontamento_nu_protocolo,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "apontamento_nu_protocolo",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n                                        filtros.apontamento_nu_protocolo\n                                    "
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Informe o protocolo que deseja encontrar "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "3",
                                  lg: "3",
                                  xl: "3"
                                }
                              },
                              [
                                !_vm.motivosRevisao.length
                                  ? _c("v-text-field", {
                                      staticClass: "mt-5",
                                      attrs: {
                                        solo: "",
                                        small: "",
                                        label: "Código Revisão Interna",
                                        hint:
                                          "Informe o código da Revisão Interna",
                                        clearable: "",
                                        "data-cy": "revisaoInterna",
                                        loading: _vm.loading,
                                        maxlength: "3"
                                      },
                                      model: {
                                        value: _vm.filtros.cd_revisao_interna,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filtros,
                                            "cd_revisao_interna",
                                            $$v
                                          )
                                        },
                                        expression: "filtros.cd_revisao_interna"
                                      }
                                    })
                                  : _c("v-autocomplete", {
                                      staticClass: "mt-5",
                                      attrs: {
                                        solo: "",
                                        small: "",
                                        label: "Código Revisão Interna",
                                        clearable: "",
                                        items: _vm.motivosRevisao,
                                        "item-value": "cd_revisao",
                                        "item-text": "cd_revisao",
                                        loading: _vm.loading
                                      },
                                      model: {
                                        value: _vm.filtros.cd_revisao_interna,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filtros,
                                            "cd_revisao_interna",
                                            $$v
                                          )
                                        },
                                        expression: "filtros.cd_revisao_interna"
                                      }
                                    }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Informe o código da Revisão Interna que deseja filtrar "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "3",
                                  lg: "3"
                                }
                              },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    solo: "",
                                    small: "",
                                    label: "Comarca",
                                    clearable: "",
                                    items: _vm.comarcas,
                                    "item-value": "cd_ibge_comarca",
                                    "item-text": "nm_comarca",
                                    loading: _vm.loading || _vm.loadingComarcas
                                  },
                                  model: {
                                    value: _vm.filtros.cd_ibge_comarca,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "cd_ibge_comarca",
                                        $$v
                                      )
                                    },
                                    expression: "filtros.cd_ibge_comarca"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(" Selecione a comarca desejada ")
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row pa-0",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "6",
                                  lg: "6"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex flex-row",
                                    attrs: {
                                      cols: "12",
                                      xs: "12",
                                      sm: "12",
                                      md: "6",
                                      lg: "6"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-right": 40,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "max-width": "290px",
                                          "min-width": "290px"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "mt-5",
                                                  attrs: {
                                                    solo: "",
                                                    small: "",
                                                    label:
                                                      "Data Apontamento Inicial",
                                                    placeholder:
                                                      "Selecione no botão ao lado",
                                                    clearable: "",
                                                    readonly: ""
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.limparData(
                                                        "apontamentoInicial"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataApontamentoInicialFormatada,
                                                    callback: function($$v) {
                                                      _vm.dataApontamentoInicialFormatada = $$v
                                                    },
                                                    expression:
                                                      "\n                                                    dataApontamentoInicialFormatada\n                                                "
                                                  }
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "grey--text ma-auto ml-2",
                                                    attrs: {
                                                      fab: "",
                                                      "x-small": "",
                                                      elevation: "0"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g({}, on),
                                                      [_vm._v(" mdi-calendar ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ]),
                                        model: {
                                          value: _vm.dataApontamentoInicial,
                                          callback: function($$v) {
                                            _vm.dataApontamentoInicial = $$v
                                          },
                                          expression: "dataApontamentoInicial"
                                        }
                                      },
                                      [
                                        _c("v-date-picker", {
                                          attrs: {
                                            max: _vm.computedMaxDate,
                                            color: "primary",
                                            locale: "pt-br"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.validarSelecaoData(
                                                $event,
                                                "dataApontamentoInicial"
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.filtros
                                                .data_apontamento_inicial,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filtros,
                                                "data_apontamento_inicial",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                                                filtros.data_apontamento_inicial\n                                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex flex-row",
                                    attrs: {
                                      cols: "12",
                                      xs: "12",
                                      sm: "12",
                                      md: "6",
                                      lg: "6"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-right": 40,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "max-width": "290px",
                                          "min-width": "290px"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function({ on }) {
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "mt-5",
                                                  attrs: {
                                                    solo: "",
                                                    small: "",
                                                    label:
                                                      "Data Apontamento Final",
                                                    placeholder:
                                                      "Selecione no botão ao lado",
                                                    clearable: "",
                                                    readonly: ""
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.limparData(
                                                        "apontamentoFinal"
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dataApontamentoFinalFormatada,
                                                    callback: function($$v) {
                                                      _vm.dataApontamentoFinalFormatada = $$v
                                                    },
                                                    expression:
                                                      "\n                                                    dataApontamentoFinalFormatada\n                                                "
                                                  }
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "grey--text ma-auto ml-2",
                                                    attrs: {
                                                      fab: "",
                                                      "x-small": "",
                                                      elevation: "0"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g({}, on),
                                                      [_vm._v(" mdi-calendar ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ]),
                                        model: {
                                          value: _vm.dataApontamentoFinal,
                                          callback: function($$v) {
                                            _vm.dataApontamentoFinal = $$v
                                          },
                                          expression: "dataApontamentoFinal"
                                        }
                                      },
                                      [
                                        _c("v-date-picker", {
                                          attrs: {
                                            min: _vm.computedMinDate,
                                            color: "primary",
                                            locale: "pt-br"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.validarSelecaoData(
                                                $event,
                                                "dataApontamentoFinal"
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.filtros
                                                .data_apontamento_final,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filtros,
                                                "data_apontamento_final",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                                                filtros.data_apontamento_final\n                                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "6",
                                  lg: "6"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    solo: "",
                                    small: "",
                                    label: "E-mail",
                                    hint:
                                      "Informe o e-mail para efetuar a busca",
                                    clearable: "",
                                    "data-cy": "emailDevedor",
                                    loading: _vm.loading
                                  },
                                  model: {
                                    value: _vm.filtros.ds_email_devedor,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "ds_email_devedor",
                                        $$v
                                      )
                                    },
                                    expression: "filtros.ds_email_devedor"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Informe o e-mail que deseja encontrar "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "6",
                                  lg: "6",
                                  xl: "6"
                                }
                              },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    solo: "",
                                    small: "",
                                    label: "Apresentante",
                                    clearable: "",
                                    items: _vm.apresentantes,
                                    "item-value": "cd_apresentante",
                                    "item-text": "nm_apresentante",
                                    loading: _vm.loading
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "text-gray": !data.item
                                                        .st_ativo
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item
                                                          .nm_apresentante
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c("br"),
                                                _c("div", [
                                                  _c("small", [
                                                    _vm._v("Remessa: ")
                                                  ]),
                                                  _c(
                                                    "small",
                                                    {
                                                      class: data.item
                                                        .st_ativo_remessa
                                                        ? "text-green"
                                                        : "text-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          !!data.item
                                                            .st_ativo_remessa
                                                            ? "Ativo"
                                                            : "Inativo"
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("small", [
                                                    _vm._v(" | Comando: ")
                                                  ]),
                                                  _c(
                                                    "small",
                                                    {
                                                      class: !!data.item
                                                        .st_ativo_comando
                                                        ? "text-green"
                                                        : "text-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          !!data.item
                                                            .st_ativo_comando
                                                            ? "Ativo"
                                                            : "Inativo"
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.filtros.cd_apresentante,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "cd_apresentante",
                                        $$v
                                      )
                                    },
                                    expression: "filtros.cd_apresentante"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Informe o apresentante que deseja encontrar - filtro por Razão social "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "3",
                                  lg: "3",
                                  xl: "3"
                                }
                              },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    solo: "",
                                    small: "",
                                    label: "Sigla Apresentante",
                                    clearable: "",
                                    items: _vm.apresentantesOrderByCd,
                                    "item-value": "cd_apresentante",
                                    "item-text": "cd_apresentante",
                                    loading:
                                      _vm.loading || _vm.loadingApresentantes
                                  },
                                  model: {
                                    value: _vm.filtros.cd_apresentante,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "cd_apresentante",
                                        $$v
                                      )
                                    },
                                    expression: "filtros.cd_apresentante"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Informe o apresentante que deseja encontrar - filtro por sigla "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex flex-row",
                                attrs: {
                                  cols: "12",
                                  xs: "12",
                                  sm: "12",
                                  md: "3",
                                  lg: "3"
                                }
                              },
                              [
                                _c("v-switch", {
                                  staticClass: "mt-0 d-flex justify-center",
                                  attrs: {
                                    "hide-details": "",
                                    small: "",
                                    color: "primary",
                                    label: "Busca exata"
                                  },
                                  model: {
                                    value: _vm.filtros.busca_exata,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filtros, "busca_exata", $$v)
                                    },
                                    expression: "filtros.busca_exata"
                                  }
                                }),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-auto ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Utilizar busca exata (aplicável ao Nosso Número) "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-col",
              {
                staticClass: "text-right",
                attrs: { xs: "12", sm: "12", md: "12", lg: "12" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { loading: _vm.loading },
                    on: { click: _vm.limparFiltros }
                  },
                  [
                    _vm._v(" Limpar Filtros "),
                    _c("v-icon", { staticClass: "ml-2" }, [
                      _vm._v("mdi-filter-remove-outline")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "ma-0",
                    attrs: { color: "primary", loading: _vm.loading },
                    on: { click: _vm.pesquisaAvancadaTitulos }
                  },
                  [
                    _vm._v(" Filtrar "),
                    _c("v-icon", { staticClass: "ml-2" }, [_vm._v("search")])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("v-data-table", {
          staticClass: "elevation-1 pa-3",
          attrs: {
            headers: _vm.headers,
            items: _vm.titulos,
            "calculate-widths": "",
            options: _vm.options,
            "server-items-length": _vm.totalTitulos,
            loading: _vm.loading,
            "footer-props": {
              itemsPerPageOptions: [10, 50, 100, 500],
              itemsPerPageText: "Itens por página:"
            },
            "loading-text": "Pesquisando títulos...",
            "no-data-text": "Nenhum resultado encontrado... Faça uma pesquisa",
            "no-results-text": "Nenhum título encontrado..."
          },
          on: {
            "update:options": function($event) {
              _vm.options = $event
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: `item.id_titulo`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(" " + _vm._s(item.id_titulo))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.id_titulo))
                        ])
                  ]
                }
              },
              {
                key: `item.ds_nosso_numero`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(item.ds_nosso_numero))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.ds_nosso_numero) + " ")
                        ])
                  ]
                }
              },
              {
                key: `item.nu_identificacao_devedor`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(_vm.formatDocumento(item)))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(_vm.formatDocumento(item)))
                        ])
                  ]
                }
              },
              {
                key: `item.nm_devedor`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(item.nm_devedor))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.nm_devedor))
                        ])
                  ]
                }
              },
              {
                key: `item.cd_status`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(_vm.getDescSituacao(item)))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(_vm.getDescSituacao(item)))
                        ])
                  ]
                }
              },
              {
                key: `item.cd_uf_devedor`,
                fn: function({ item }) {
                  return [
                    item.ds_revisao != "" && item.cd_status == 99999
                      ? _c(
                          "span",
                          {
                            style: { color: "red" },
                            attrs: {
                              title: "Este título precisa ser revisado. "
                            }
                          },
                          [_vm._v(_vm._s(item.cd_uf_devedor))]
                        )
                      : _c("span", { style: { color: "black" } }, [
                          _vm._v(_vm._s(item.cd_uf_devedor))
                        ])
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(props) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2 grey white--text",
                        style: { color: "primary" },
                        attrs: {
                          elevation: "1",
                          width: "100%",
                          "full-width": "",
                          small: "",
                          "data-cy": `editarTitulo${props.index}`
                        },
                        on: {
                          click: function($event) {
                            return _vm.abrirDetalhesTitulo(props.item)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-2", style: { color: "white" } },
                          [_vm._v(" edit ")]
                        ),
                        _vm._v(" Detalhes ")
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }